import React, { useState, useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { dateFromMillisecond } from '../config/Configuration';
import Simg from '../images/no-image-256.png';
import blackCircle from '../images/blackCircle.jpg';
import axios from '../api/axios';
import {useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../css/ConfirmationBox.css'
import { toast } from 'react-toastify';
import { fetchAd, myAd } from '../redux/store/actions/adActions';
import { startLoading } from '../redux/store/actions/actionsTypes';
import { checkImpressionFunc } from '../config/fbAdImpression';


function FacebookAdBox(props) {
    const dispatch = useDispatch();
    const maxLength = 100;
    const [isExpanded, setIsExpanded] = useState(false);
    const record = JSON.parse(props.item.record);
    const publisherPlatform = record ? record.publisherPlatform : '';
    const token = JSON.parse(localStorage.getItem('token'));
    const [impressionData, setImpressionData] = useState([]);
    const [brandImage, setBrandImage] = useState(false);
    const [mediaImage, setMediaImage] = useState(false);

    useEffect(() => {
        getData(record);
        loadDefauldMedia();
    }, []);

    const loadDefauldMedia = () => {
        let tempBrandImg = '';

        if (props.item.ad_logo) {
            tempBrandImg = process.env.REACT_APP_BACKEND_BASE_URL+'/' + props.item.ad_logo;

        }
        else if (record.snapshot.page_profile_picture_url) {
            tempBrandImg = record.snapshot.page_profile_picture_url;

        }
     
        setBrandImage(tempBrandImg);

        let tempMediaImg = '';

        if (props.item.ad_image) {
            tempMediaImg = process.env.REACT_APP_BACKEND_BASE_URL+'/' + props.item.ad_image;

        }
        else if (props.item.cardResizedImageUrl) {
            tempMediaImg = props.item.cardResizedImageUrl;

        }

        setMediaImage(tempMediaImg);
    };

    async function getData(record) {
    }
    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const onErrorBrandImage = () => {
        setBrandImage(blackCircle);
    };

    const onErrorMediaImage = () => {
        setMediaImage(Simg);
    };

    const handleCheckSpend = async () => {
        const data = await checkImpressionFunc(record);
        setImpressionData(data);
    }

    const handleSaveAd = async (id) => {
        dispatch(startLoading());
        const config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        const bodyParameters = {
            id: id
        };
        axios
            .post("/dashboardAds/save", bodyParameters, config)
            .then((response) => {
                if (response.data.status) {
                    toast.success(response.data.message);
                    setTimeout(
                        function () {
                            dispatch(fetchAd());
                            dispatch(myAd());


                        }
                            .bind(this),
                        3000
                    );

                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((err) => {
                toast.error(err.message || "An error occurred. Please try again.");

            });
    };
    const handleUnsaveOrDelete = async (id) => {


        dispatch(startLoading());

        const config = {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        };
        const bodyParameters = {
            id: id
        };
        axios
            .post("/dashboardAds/save", bodyParameters, config)
            .then((response) => {
                if (response.data.status) {
                    toast.success(response.data.message);
                    setTimeout(
                        function () {
                            dispatch(fetchAd());
                            dispatch(myAd());
                        }
                            .bind(this),
                        3000
                    );

                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((err) => {
                toast.error(err.message || "An error occurred. Please try again.");

            });

    };
    const handleConfirmation = (id) => {
        const action = props.pageName === 'myAds' ? 'delete' : 'unsave';
        const confirmationMessage = `Are you sure you want to ${action} this ad?`;

        confirmAlert({
            customUI: ({ onClose }) => (
                <div className="custom-confirmation-box">
                    <h2 className="confirmation-title">Confirm Action</h2>
                    <p>{confirmationMessage}</p>
                    <div className="confirmation-buttons">
                        <button
                            style={{ backgroundColor: '#09D7A6', color: '#fff' }}
                            onClick={() => {
                                handleUnsaveOrDelete(id);
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                        <button
                            style={{
                                backgroundColor: '#fff',
                                color: '#09D7A6',
                                border: '1px solid #09D7A6',
                            }}
                            onClick={onClose}
                        >
                            No
                        </button>
                    </div>
                </div>
            ),
            overlayClassName: "custom-overlay", 
        });
    };

    return (

        <>
            {(() => {

                if (props.revanueValue == 0 || (impressionData['day'] && impressionData['day'] < props.revanueValue)) {
                   
                    return (
                        <>
                            <div className='col-md-3'>
                                <div className='download-media'>
                                    <div className='bookmark-title'><span>Library IDs: {props.item.library_id}</span>  <a
                                        className={props.item.myads.length > 0 ? "bookmark-saved" : ""}
                                        onClick={(e) => props.item.myads.length > 0 ? handleConfirmation(props.item.id) : handleSaveAd(props.item.id)}
                                        href="#">
                                        <i
                                            className={`fa-solid ${props.item.myads.length > 0
                                                    ? props.pageName === 'myAds'
                                                        ? 'fa-trash'
                                                        : 'fa-bookmark'
                                                    : 'fa-bookmark'
                                                }`}
                                        ></i></a>
                                    </div>
                                    <div className='d-media'>
                                        <div className='d-media-left'>
                                            <div className='d-media-active'><i className="fa-solid fa-circle-check"></i>Active</div>
                                            <p>Started running on {dateFromMillisecond(props.item.startDate)} </p>
                                            <div className='d-media-active'>Platform
                                                {publisherPlatform && publisherPlatform.length > 0 ? (
                                                    publisherPlatform.map(socialName => (


                                                        <>

                                                            <i class={'fa-brands fa-' + socialName}></i>

                                                        </>
                                                    ))
                                                ) : ('')}

                                            </div>
                                            <p>This ad has multiple versions   <i className="fa-solid fa-exclamation"></i></p>
                                        </div>

                                        {/* <div className='d-media-right'>
                            <span className='views-price'> {props.checkImpression?props.checkImpression:'No Data'}</span>
                        </div> */}

                                        <div className='d-media-right'>
                                            {Object.keys(impressionData).length == 0 ? <button type='button' onClick={handleCheckSpend} class="check-spend views" >Check Spend</button> : ''}
                                            {Object.keys(impressionData).length > 0 ? <>
                                                <span className='views'>{impressionData['views'] ? impressionData['views'] : 0} Views</span>
                                                <span className='views-price'>€{impressionData['spend'] ? impressionData['spend'] : 0}</span>
                                                <span className='views-time'>{impressionData['day'] ? impressionData['day'] : 0}€/ Days</span>
                                            </> : ''}
                                        </div>
                                        {/* <div className='d-media-right'>
                            <span className='views'>{props.checkImpression['views']} Views</span>
                            <span className='views-price'>€{props.checkImpression['spend']}</span>
                            <span className='views-time'>{props.checkImpression['day']}€/ Day</span>
                        </div> */}
                                    </div>
                                    <a target='_blank' href={record && record.snapshot ? record.snapshot.link_url : ''} className='download-media-addbtn'>{/*record.snapshot ? record.snapshot.cta_text : ''*/} {/*Ads Media*/}Call To Action</a>
                                    <div className='sponsered-div'>
                                        {(() => {

                                            return (<>
                                                <img src={brandImage} onError={() => onErrorBrandImage()} alt='sponser' />
                                            </>)

                                        })()}
                                        <div className='sponser-right'>
                                            <div className='sponser-title'>{record.snapshot ? record.snapshot.page_name : ''}</div>
                                            <p>Sponsored</p>
                                        </div>
                                    </div>
                                    <p>
                                        {(() => {
                                            if (props.item.cardBody) {
                                                return (

                                                    <>
                                                        {isExpanded ? props.item.cardBody : `${props.item.cardBody.substring(0, maxLength)}...`}
                                                        <button className='check-spend more' onClick={toggleReadMore}>
                                                            {isExpanded ? 'Read Less' : 'Read More'}
                                                        </button>
                                                    </>
                                                )
                                            }
                                        })()}
                                    </p>
                                    {/* <ul className='sponser-ul'>
                          <li>✅ 🏆Free Delivery🎉Min. 50% Off⭐COD</li>
                          <li>✅ Premium Shirts & Kurta at Best price!</li>
                          <li>✅ Get Extra Discount at Checkout! 🎉</li>
                        </ul> */}
                                    <img className='s-img' src={mediaImage} alt='s-img' onError={() => onErrorMediaImage()} />
                                </div>
                            </div>
                        </>)
                }
            })()}
        </>
    )
}

export default FacebookAdBox