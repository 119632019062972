import React, { useState, useEffect } from 'react';


import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import FacebookAdBox from '../components/FacebookAdBox';
import { useSelector, useDispatch } from 'react-redux';



import axios from '../api/axios';

import { fetchAd } from '../redux/store/actions/adActions';
import { MultiSelect } from "react-multi-select-component";

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { checkImpressionFunc } from '../config/fbAdImpression';
import { startLoading, stopLoading } from '../redux/store/actions/actionsTypes';

import AdFilter from '../components/AdFilter';
//import { getAdsLibraryData } from '../config/facebookAdsService';

import PlanSubscribeChecker from '../components/PlanSubscribeChecker';


function Dashboard() {
  /* range */
  const [revanueValue, setRevanueValue] = useState(0); // Initial value of the slider

  const [searchTerm, setSearchTerm] = useState('');
  const [adsData, setAdsData] = useState([]);

  const token = JSON.parse(localStorage.getItem('token'));


  const handleChange = (newValue) => {
    setRevanueValue(newValue);
  };



  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);
  //const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState([]);
  const [fiterOption, setFilterOption] = useState([]);

  const dispatch = useDispatch();



  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {

    const queryParams = new URLSearchParams(window.location.search);

    const status = queryParams.get("status");
    const subscriptionPaymentId = queryParams.get("subscriptionPaymentId");
    const session_id = queryParams.get("session_id");
    if (status == 'true' && subscriptionPaymentId != null && subscriptionPaymentId > 0) {
      //   alert(subscriptionPaymentId);
      updatePaymentStatus(status, subscriptionPaymentId,session_id);
    }

    // callbackDropData();
    /*   const data =  getAdsLibraryData('shopify');
      console.log('adsData',data); */

  }, []);


  useEffect(() => {
    dispatch(fetchAd());
  }, [dispatch]);

  const handleRevanueChange = (newValue) => {
   // alert(newValue);

    setRevanueValue(newValue);
  };

  const callbackUniqueDropData = (mappedArray) => {
    if (mappedArray.length > 0) {

      //  const mappedArray = products.ads.data.map(({ id, pageName }) => ({ value: id, label: pageName }));

      const uniqueNames = new Set();
      const result = mappedArray.filter(({ label }) => {
        if (uniqueNames.has(label)) {
          return false;
        } else {
          uniqueNames.add(label);
          return true;
        }
      });
      return result;
      //  setFilterOption(result);
    }

  }

  const products = useSelector((state) => state.ad);


  const callbackSelectedAd = (selectedDatas) => {

    console.log(selectedDatas);
    setSelected(selectedDatas);
    //  getAllAds();
  };

  const updatePaymentStatus = async (status, id,session_id) => {


    const config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    };

    const bodyParameters = {
      id: id,
      status: status,
      stripe_session_id:session_id
    };
    await axios
      //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
      .post("/pricing/updatePaymentStatus", bodyParameters, config)
      .then((res) => {
        console.log('res.result', res.data);


        //   setLoader(false);
      })
      .catch((err) => {
        //   setLoader(false);
      });
  };

  const filterArr = [];


  function userPageName(username) {
    if (selected.length > 0) {
      return selected.some(function (el) {
        //  console.log('el.pageName', el);
        return el.label === username;
      });
    } else {
      return false;
    }
  }



  return (
    <>


      <PlanSubscribeChecker />
      <DashboarderHeader />
      <div className="container-fluid">
        <div className="row">
          <DashboarderSidebar />


          <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
          <div className="col-md-9 col-lg-10 ml-md-auto px-0 custom-nine-col">
            <main className="container-fluid main-container">
            <AdFilter
                filterArr={filterArr}
                setSelected={setSelected}
                selected={selected}
                handleRevanueChange={handleRevanueChange}
                revanueValue={revanueValue}
              />

              <div className='row'>
                {
                  (products.ads.data &&
                  products.ads.data.length > 0 )?
                  products.ads.data.map(
                    (item, i) => {

                      const record = JSON.parse(item.record);

                      const arr = {};
                      arr['label'] = record.pageName;
                      arr['value'] = item.id;

                      filterArr.push(arr);

                     // setFilterOption(fiterOption => [...fiterOption,arr] );

                      if ((userPageName(record.pageName) == true || selected.length == 0)) {
                        return (<>
                          <FacebookAdBox
                            item={item}
                            revanueValue = {revanueValue}
                            pageName="Dashboard"
                          />
                        </>)
                      }

                    }

                  ): (
                  
                    <div className="no-data-container text-center">
                      <img src="/noAd.png" alt="No Data" className="no-data-image" />
                      <h3>No Ads Available</h3>
                      <p>It looks like you don’t have any ads to display.</p>
                      <div className="button-group">
                        <button
                          className="btn btn-secondary"
                          onClick={() =>
                            window.location.href = 'https://www.facebook.com/ads/library/?active_status=active&ad_type=political_and_issue_ads&country=IN&is_targeted_country=false&media_type=all'
                          }
                        >
                          Go to Facebook Ad Library
                        </button>

                      </div>
                    </div>
                  
                )

                }


              </div>

             
            </main>
          </div>
        </div>
      </div>


    </>
  )
}


export default Dashboard